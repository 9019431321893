import React from "react"
import { Link } from "gatsby"

import Grid from "@material-ui/core/Grid";

import BarChartIcon from '@material-ui/icons/BarChart';
import DevicesIcon from '@material-ui/icons/Devices';

function Introduction(){

    return(
        <div style={{
            maxWidth: "750px",
            border: "auto",
            margin: "auto",
            paddingBottom: "10px"
            }}>


       
        <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="center"
      >

      <Grid item xs={6} style={{textAlign: "center"}}>
          
            <BarChartIcon 
            color="action"
            style={{ height: "80px", width: "100px", paddingTop: "0px", color: "steelBlue" }}/>
            </Grid>


            <Grid item xs={6} style={{textAlign: "center"}}>
            <DevicesIcon 
            style={{ height: "80px", width: "100px", paddingTop: "0px", color: "steelBlue"}}/>
            </Grid>

      </Grid>




      <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="center"
      >

        <Grid item xs={6} style={{
            textAlign: "center",
            fontSize: "0.95rem",
            padding: "10px",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}>
            
          <h3 style={{color: "steelBlue", fontSize: "1.2rem"}}>Data Science</h3>
        </Grid>

        <Grid item xs={6} style={{
            textAlign: "center",
            fontSize: "0.95rem",
            padding: "10px",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}>
            
          <h3 style={{color: "steelBlue", fontSize: "1.2rem"}}>Web Development</h3>
        </Grid>

      
        </Grid>


        <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="center"
      >

        <Grid item xs={6} style={{
            textAlign: "center",
            fontSize: "0.95rem",
            padding: "10px",
            paddingTop: "0px",
            paddingBottom: "20px",
          }}>
            
          <p style={{fontSize: "1rem"}}>Data analysis and visualisations, finding insights in data to create beautiful articles.</p>
        </Grid>

        <Grid item xs={6} style={{
            textAlign: "center",
            fontSize: "0.95rem",
            padding: "10px",
            paddingTop: "0px",
            paddingBottom: "20px",
          }}>
            
          <p style={{fontSize: "1rem"}}>Mobile first web development, interactive web apps made using fast web technologies.</p>
        </Grid>

      
        </Grid>
      

<Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="center"
      >

      <Grid item xs={12} style={{
            textAlign: "center",
            fontSize: "1rem",
            padding: "10px",
            paddingTop: "0px",
            paddingBottom: "10px",
          }}>


          <p>Campbell Data Science is all about combining data science and web development to create interactive data articles that you want to share. Follow this link for <Link to="/infographics/" style={{ textDecoration: "none" }}>
                infographics
                </Link> or learn more about CDS on the{" "}
              <Link to="/about/" style={{ textDecoration: "none" }}>
                 about page
                </Link>.</p>

                {/* <p>Campbell Data Science is all about combining data science and web development to create interactive data articles that you want to share. Learn more about CDS on the{" "}
              <Link to="/about/" style={{ textDecoration: "none" }}>
                 about page
                </Link>.</p> */}


           <p>I make content around based on anything that interests me in data science, web development and marketing. I create data visualisations and written articles based on information I find around the web. From an analysis of some of the largest companies in the world to a look into data I have scraped together for a product that catches my attention.</p>
             

             
          </Grid>

          </Grid>


    {/* <hr style={{
        borderTop: "0px solid", 
        color: "steelBlue", 
        width: "90%", 
        marginLeft: "auto", 
        marginRight: "auto", 
        marginBottom: "12px" }}/> */}



        </div>
    )
}

export default Introduction