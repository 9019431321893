import React from "react"
import { Link } from "gatsby"

// import Image from "../components/image"

const PostLink = props => (
  <div
    style={{
      // backgroundColor: "#FFFAFA",
      border: "1px solid 	#DCDCDC",
      maxWidth: `500px`,
      marginBottom: `1.45rem`,
      padding: "10px",
      borderRadius: "5px"
      // boxShadow: "2px 2px 3px #D3D3D3",
    }}
  >
    <Link to={props.link} style={{ textDecoration: "none" }}>
      <h2>{props.title}</h2>
    </Link>

    <Link to={props.link} style={{ textDecoration: "none" }}>
    <p>{props.description}</p>
    </Link>
    
    <Link to={props.link} style={{ textDecoration: "none" }}>
      continue reading
    </Link>
  </div>
)

export default PostLink
