import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import PostLink from "../components/postLink"
import Introduction from "../components/introduction"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="Web Design and Marketing. We create beautiful mobile first web apps that are fast and responsive. Built to engage and inform, backed by data science." />
    {/* <h1
      style={{
        fontSize: "1.6rem",
        color: "#3d3d3d"
      }}
    >
      Welcome
    </h1> */}


    <Introduction />


    <p>
      Welcome to Campbell Data Science, Here's our{" "} <Link to="/privacy-policy/" style={{ textDecoration: "none" }}>
        privacy policy
      </Link>. Browse the articles below to find what you are looking for...
    </p>
    {/* <p>
      <Link to="/test/test/" style={{ textDecoration: "none" }}>
        test sub-directory
      </Link>
    </p> */}

    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}

    {/* Data Visualisation */}
    {/* <PostLink
      title="Data Example"
      description="data example article"
      link="/data-example/"
    /> */}

    {/* <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>DATA SCIENCE AND WEB DEVELOPMENT</h2> */}


    {/* Visualisation */}
    <PostLink
      title="Alphabet Vs Amazon"
      description="A look at some of the important numbers behind these tech giants to see how they stack up. A visualisation comparing the companies in FAANG "
      link="/alphabet-vs-amazon/"
    />


    
    {/* Data Science and web development */}
    <PostLink
      title="Machine Learning in Web Development"
      description="Machine learning web development app example using React js and Tensorflow js. Train and deploy the model right in the browser using JavaScript"
      link="/machine-learning-web-dev/"
    />
    <PostLink
      title="Why Data Scientists Should Learn Web Development"
      description="Data science is an amazing subject. It is commonly described as being an instrument that will help create so much growth that it will put us through the next industrial revolution. This field is so versatile that it can benefit pretty much every single industry if used correctly."
      link="/data-scientist-learn-web-development/"
    />
    <PostLink
      title="The Best Data Science and Web Development Resources"
      description="As you guys know, data science and web development are two of my favourite subjects. I've been lucky enough to be able to use what I have learned every day and now I think it's time to give back."
      link="/best-data-web-resources/"
    />

    


    {/* <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>INFOGRAPHICS</h2>     */}
    <Link to="/infographics/" style={{ textDecoration: "none" }}>
    <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>INFOGRAPHICS</h2>
    </Link>
    {/* Infographics */}
    <PostLink
      title="Why You Should Use Infographics as Part of Your SEO and Business Strategy"
      description="Search engine optimisation is an important part of your marketing strategy. When done right, it can help you reduce expenses because you are able to pull in more leads without spending on ads."
      link="/infographics-for-seo/"
    />

    <PostLink
      title="6 Tips For Designing Your Own Infographics to Get More Leads"
      description="Infographics should leave your leads informed and more knowledgeable about you and your business. Here's everything you need to know about designing your own infographics"
      link="/infographic-design/"
    />

    <PostLink
      title="Outsourcing Infographics: Everything You Need To Get Started"
      description="You’ve decided you want hire a designer to get some infographics made and don’t know where to start? Here are your options and tips on what to do next."
      link="/outsourcing-infographics/"
    />


    <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>GUIDES & INFORMATION</h2>
    {/* Setup guides, help and informational */}
    <PostLink
      title="Deploying a react app to your custom domain"
      description="When I first started using react, I looked around out of curiosity to see how I would publish my project but found nothing. Every tutorial was saying how easy it is to deploy from code sandbox to Netlify. This is not what I wanted and it was frustrating, it was like every writer just copies from each other."
      link="/deploy-react-to-production/"
    />
    <PostLink
      title="Website Setup Checklist"
      description="This is the quickest & easiest way to set up a low cost website with a custom domain. These are the essentials you need to get your site online today."
      link="/web-setup-checklist/"
    />
    <PostLink
      title="10 Things You Should Know About Domain Names"
      description="I have bought many domain names over the years, some I still own today. When it comes to knowledge around domains, we don’t need to know everything but some things definitely help. This list will help you make a better decision when you are buying yours. I will answer some questions I get frequently like where to buy a domain name and how much it costs. Also, security and optional extras."
      link="/10-things-about-domain-names/"
    />
    <PostLink
      title="Why You Need an Online CV"
      description="Finding a new job can be difficult. There are lots of opportunities out there but there is also a lot of people out there applying for each position. This means lots of competition."
      link="/why-you-need-an-online-cv/"
    />
    <PostLink
      title="Setting up Your HTML Website Using cPanel"
      description="I am going to take you through uploading your html page into a subdirectory through cPanel. I am using SiteGround but if you have access to cPanel through another host, this will work."
      link="/html-website-setup-cpanel/"
    />
    <PostLink
      title="My Website Structure and Setup Guide"
      description="If you’ve read earlier articles or my about page then you know I got started in data science. Primarily using Python and a bit of R just to see how another language worked. I felt restricted with the options to share my work so I got set up with WordPress."
      link="/website-setup-guide/"
    />
    <PostLink
      title="Why You Should Use WordPress as a Web Developer"
      description="There are lots of arguments for and against the use of WordPress in the world of web development. There are many reasons for not using it such as the amount of bloat in the background and the lack of control. I could go on about the negatives but that is a subject for another article."
      link="/why-use-wordpress/"
    />
    <PostLink
      title="How Should I learn web development and data science?"
      description="Everything you should think about when making the choice between university, online courses and being self-taught. Choosing how to learn a subject depends on how you are as a person and how much time you have..."
      link="/how-should-i-learn-web-development-and-data-science/"
    />
    <PostLink
      title="6 Steps to Monetize Your Website"
      description="Everything you need to know to monetize your website. You've done the hard part in figuring out how to get traffic, here's how to turn traffic into money..."
      link="/6-steps-to-monetize-your-website/"
    />

    <PostLink
      title="6 Benefits of a Subscription Business Model"
      description="You might have noticed that subscriptions are everywhere now. If something can be made into a subscription service then it has probably been done already..."
      link="/6-benefits-of-subscription-business-model/"
    />

    <PostLink
      title="How to Create Every Day"
      description="One of the main things that stop people from starting to create content is this fear that they will run out of ideas..."
      link="/how-to-create-every-day/"
    />

    <PostLink
      title="Why You Should Learn The Skill Not the Tool"
      description="The point is not to get too caught up in learning the tools when it’s the skills that will help you the most. Tools come and go whereas skills develop if you nurture them..."
      link="/learn-skill-not-tool/"
    />

    <PostLink
      title="Marketing Your First Ebook"
      description="Another benefit is that an ebook is achievable. For a first step into making digital products, it’s one of the easier options, depending on what you want to write..."
      link="/marketing-your-first-ebook/"
    />

    <PostLink
      title="The Good and Bad of Meta"
      description="Over the past few days, I had heard whispers of a Facebook name change. As I was listening, my mind wandered into projections of the future and what the world would be like, should this idea succeed.."
      link="/the-good-and-bad-of-meta/"
    />

    <PostLink
      title="Lifestyle Creep and How to Avoid it"
      description="It might sound easy, the logic is simple but the truth is that a great deal of us live month to month despite an increase in pay.."
      link="/lifestyle-creep-how-to-avoid/"
    />

    <PostLink
      title="Building Something on The Side"
      description="Should you build something on the side, what are your chances of success and how long will it take before you can make a living from it?.."
      link="/building-something-on-the-side/"
    />

    <PostLink
      title="How Working For Free Can Be a Good Thing"
      description="It might sound counter intuitive but one of the quickest ways around this problem is to work for free. It works because it lets you ignore the cost part of the equation..."
      link="/working-for-free-can-be-good/"
    />

    <PostLink
      title="Why You Shouldn’t Just Rely on SEO"
      description="Ever changing algorithm - SEO is an amazing tool if you get it right. It can be frustrating initially because it usually takes a while to see any worthwhile results..."
      link="/why-you-shouldnt-just-rely-on-seo/"
    />

    <PostLink
      title="The Tech Stack For My Site and Why"
      description="When I decided to re-build my website, I wanted to make use of the things I had learned and keep it simple to avoid any problems later on..."
      link="/the-tech-stack-for-my-site/"
    />

    <PostLink
      title="The Best Article Type For SEO"
      description="When there’s so much competition out there, it always helps to niche down on a subject if you want to get noticed. Writing a general article on lots of different ideas won’t be as focussed as one that takes a topic and explains it really well...."
      link="/the-best-article-type-for-seo/"
    />

    <PostLink
      title="Setting Goals and Working With Your Subconscious"
      description="When I want to set a long term goal, I try to break it down and phrase it in such a way that I can repeat to myself easily. Ideally it should have three points or less so there is very little chance of me forgetting...."
      link="/goals-and-working-with-your-subconscious/"
    />

    <PostLink
      title="Bad Ideas Become Good Ideas"
      description="You are searching for something that will change the world for the better, make you rich or both at the same time. You need a great idea then you need to execute on it....."
      link="/bad-ideas-become-good-ideas/"
    />


    <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>OUTSOURCING</h2>
    {/* Fiverr */}
    <PostLink
      title="Outsourcing Work Online with Fiverr"
      description="In business, time is a valuable resource. We have all heard the saying; time is money. If one person tried to do everything, they would not get very far. This is why we hire talent internally and outsource work externally. This allows us to free up time and get the important things done that only we can do or things that bring in the majority of the profit."
      link="/outsourcing-with-fiverr/"
    />
    <PostLink
      title="Logo Design on Fiverr: The Ultimate Guide"
      description="A good logo for your business is important for many reasons. I guess you already knew that which is why you are here. You probably already know how useful outsourcing jobs can be too. It can a great time saver and lets you focus on the things you do best. "
      link="/fiverr-logo-design/"
    />
    
    <h2 style={{paddingLeft: "10px", color: "steelBlue"}}>REVIEWS</h2>
    {/* Reviews */}
    <PostLink
      title="SiteGround Review"
      description="Here’s why I chose SiteGround as my web host and what I think of it after 1 year of use. I hope it helps you in making your decision on if you should try site ground hosting."
      link="/siteground-review/"
    />
    <PostLink
      title="Clean My Mac Review"
      description="CleanMyMac is the perfect option for quickly clearing your cache, ram and solving your full disk problems. Honest review from a real user"
      link="/cleanmymac-review/"
    />
    
    
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
